// import OtpInput from "@bachdgvn/vue-otp-input"
// import App from '../app.vue';

// Vue.component('v-otp-input', OtpInput)
// Vue.component('v-phone-input', VuePhoneNumberInput)

// document.addEventListener('turbolinks:load', () => {

// 	var element = document.getElementById("vuelogin");

// 	var app = new Vue({
// 		el: element,
// 		// strict: true,
// 		mixins: [turbolinksAdapterMixin],
// 		data: function(){
// 			return{ 
// 			}
// 		},
// 		computed: {
// 		},
// 		methods:{
// 			send_otp: (_event)=>{
// 				document.getElementById('otp_attempt').value = _event;
// 				document.getElementById('edit_user').submit();
// 			}
// 		}
// 	});
// })
